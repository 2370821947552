
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { injected } from 'src/components/wallet/connectors';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { CircularProgress, Switch } from '@mui/material';
import toast from 'react-hot-toast';
import useFetch from 'src/hooks/useFetch';
// import { AppContext, AppEnv } from 'src/adminRoutes';
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface IAppbar {
    open: boolean
    toggleDrawer: () => void,
    selectedRoute: any,
    onClickLogout: () => void
}

const networkSymbol: any = {
    1: "ETH",
    4: "Rinkeby"
}

function Appbar(props: IAppbar) {
    const { open, toggleDrawer, selectedRoute, onClickLogout } = props
    const isPartnerAdmin = process.env.REACT_APP_ENV_STATE === "partner"

    const [connectLoader, setConnectLoader] = useState(false)
    const [isConnected, setIsConnected] = useState("initial")
    const { chainId = 0, activate, account, active, deactivate } = useWeb3React()

    // const { appEnv = "dev", onChangeEnvironment = () => { } } = useContext(AppContext);

    const walletConnecting = useRef(false)

    const { postRequest: checkIfTokenIsValid } = useFetch(`/api/v1/${isPartnerAdmin ? "partner" : "admin"}/validate`)

    useEffect(() => {
        (async () => {
            const { error } = await checkIfTokenIsValid() as any
            if (error) {
                toast.error("Session expired, please login")
                return onClickLogout()
            }
            let wasConnected = sessionStorage.getItem("connected") as any
            wasConnected = wasConnected ? JSON.parse(wasConnected) : {}
            if (wasConnected?.connection) {
                connectToWallet()
            }

        })()
    }, [])

    useEffect(() => {
        if (!active && isConnected === "success" && !chainId) {
            toast.error("Chain not supported")
        }
    }, [active, isConnected, chainId])

    useEffect(() => {
        if (active) toast.success("Wallet connected")
    }, [active])

    async function connectToWallet() {
        try {
            walletConnecting.current = true
            setConnectLoader(true)
            setIsConnected("initial")
            await activate(injected)
            sessionStorage.setItem("connected", JSON.stringify({ connection: true }))
            setIsConnected("success")
            setConnectLoader(false)
        } catch (ex) {
            setConnectLoader(false)
            console.log(ex)
        }
    }

    async function disconnectToWallet() {
        setIsConnected("disconnect")
        deactivate()
        sessionStorage.removeItem("connected")
        toast.success("Wallet disconnected")
    }

    const shortAddress = useMemo(() => {
        if (account) {
            return `${account.substring(0, 3)}...${account.substring(account.length - 4)}`
        }
        return ""
    }, [account])

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar sx={{ pr: '24px', /* keep right padding when drawer closed */ }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    {(selectedRoute && selectedRoute?.name) || ''}
                </Typography>
                {/* {
                    !isPartnerAdmin ? <div style={{ marginRight: 20 }}>dev<Switch checked={appEnv === "prod"} onChange={(e) => onChangeEnvironment(e.target.checked ? AppEnv.PROD : AppEnv.DEV)} />prod</div> : null
                } */}
                {
                    // isPartnerAdmin ?
                    active ?
                        <div className="wallet-connected" onClick={disconnectToWallet}>{networkSymbol[chainId] ? `[${networkSymbol[chainId]}]` : ""} Connected to {shortAddress}</div>
                        : <button className="connect-to-wallet" onClick={connectToWallet}>
                            {connectLoader ? <CircularProgress size={18} style={{ color: "black" }} /> :
                                "Connet Wallet"}
                        </button>
                    // : null
                }
                <IconButton color="inherit" onClick={onClickLogout}>
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default Appbar