import { useState, useMemo, cloneElement } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainListItems, { adminRouteList, partnerRouteList } from './listItems';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { removeAuthObjects } from 'src/utils';
import Appbar from './Appbar';
import { CircularProgress } from '@mui/material';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function HomepageHOF(props: any) {
    const [open, setOpen] = useState(true);
    const [loader, setLoader] = useState<{ action: boolean, message: string }>()
    const history = useHistory()
    const routeMatch = useRouteMatch()
    const pathName = routeMatch.path

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const selectedRoute = useMemo(() => {
        const routeList = process.env.REACT_APP_ENV_STATE === "admin" ? adminRouteList : partnerRouteList
        return routeList.find(route => route.activeRoutes.includes(pathName))
    }, [pathName])

    const onClickLogout = () => {
        removeAuthObjects()
        history.push('/login')
    }

    const setIsLoading = (action: boolean = false, message: string = '') => {
        setLoader({ action, message: !action ? "" : message })
    }

    const disableMaxWidth = pathName.includes("cohort_staking")

    return (
        <Box sx={{ display: 'flex' }}>
            {loader?.action ? <div className='main-circular-loader'>
                <div style={{ textAlign: "center" }}>
                    <CircularProgress size={22} style={{ color: "white" }} />
                    {loader?.message ? <p style={{ marginTop: 10 }}>{loader?.message}</p> : null}
                </div>
            </div> : null}
            <CssBaseline />
            <Appbar
                open={open}
                toggleDrawer={toggleDrawer}
                selectedRoute={selectedRoute}
                onClickLogout={onClickLogout}
            />
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1]
                    }}
                >
                    <img src="/images/gen-logo.png" alt="" style={{ height: "34px" }} />
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MainListItems selectedRoute={selectedRoute} />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth={disableMaxWidth ? false : "lg"} sx={{ mt: 4, mb: 4 }}>
                    {cloneElement(props.children, { setLoader: setIsLoading })}
                </Container>
            </Box>
        </Box>
    );
}