import { lazy, Suspense, useState, createContext, useContext, useEffect } from "react"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminHof from "src/containers/HOF/AdminHOF"
import { getCookie } from "./utils";
import { APPLICATION_TOKEN } from "./constants";

const Login = lazy(() => import('src/containers/Auth/Login'))
const Dashboard = lazy(() => import('src/containers/Dashboard'))
const Products = lazy(() => import('src/containers/Projects'))
const Product = lazy(() => import('src/containers/Projects/Project'))
const ViewProject = lazy(() => import('src/containers/Projects/ViewProject'))
const ViewAllocation = lazy(() => import('src/containers/Projects/ViewAllocation'))
const Activities = lazy(() => import('src/containers/Activity'))
const Activity = lazy(() => import('src/containers/Activity/Activity'))
const Events = lazy(() => import('src/containers/Events'))
const Event = lazy(() => import('src/containers/Events/Event'))
const Academies = lazy(() => import('src/containers/Academies'))
const Academy = lazy(() => import('src/containers/Academies/Academy'))
const Voting = lazy(() => import('src/containers/Voting'))
const Vote = lazy(() => import('src/containers/Voting/Vote'))
const CohortStaking = lazy(() => import('src/containers/CohortStaking'))
const CohortStake = lazy(() => import('src/containers/CohortStaking/CohortStake'))
const Partners = lazy(() => import('src/containers/Partners'))
const Partner = lazy(() => import('src/containers/Partners/Partner'))
const Blogs = lazy(() => import('src/containers/MoxieBlog'))
const Blog = lazy(() => import('src/containers/MoxieBlog/MoxieBlog'))
const User = lazy(() => import('src/containers/MoxieUser'))
const GSUser = lazy(() => import('src/containers/GSUser'))

const lazySuspense = <div className="lazy-suspense-loader"><h2>Loading Interface...</h2></div>

// export enum AppEnv {
//   DEV = "dev",
//   PROD = "prod"
// }

// export interface AppContextInterface {
//   appEnv: AppEnv,
//   onChangeEnvironment: (env: AppEnv) => void
// }

// export const AppContext = createContext({} as AppContextInterface);

export default function App() {
  const [isDarkMode, setIsDarkMode] = useState(true)
  // const [appEnv, setAppEnv] = useState<AppEnv>(AppEnv.DEV)

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#ffffff',
        dark: '#373737',
        light: "#FF0071"
      },
      secondary: {
        main: '#373737',
      },
      background: {
        default: '#09090A',
        paper: "#09090A"

      }
    },
  });

  // useEffect(() => {
  //   const storedEnv = localStorage.getItem("APP_ENV") as AppEnv
  //   const devUrl = process.env.REACT_APP_API_BASE_URL_DEV
  //   const prodUrl = process.env.REACT_APP_API_BASE_URL_PROD
  //   if (storedEnv) {
  //     Axios.defaults.baseURL = storedEnv === AppEnv.DEV ? devUrl : prodUrl
  //     setAppEnv(storedEnv)
  //   } else {
  //     Axios.defaults.baseURL = devUrl
  //     setAppEnv(AppEnv.DEV)
  //   }
  // }, [])

  // const onChangeEnvironment = (env: AppEnv) => {
  //   localStorage.setItem("APP_ENV", env)
  //   setAppEnv(env)
  //   window.location.reload()
  // }

  // const contextState = {
  //   appEnv,
  //   onChangeEnvironment,
  // }

  return (
    // <AppContext.Provider value={contextState}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={lazySuspense}>
          <Router>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />

              <PrivateRoute exact path="/academy" component={Academies} />
              <PrivateRoute exact path="/academy/new" component={Academy} />
              <PrivateRoute exact path="/academy/:id" component={Academy} />

              <PrivateRoute exact path="/projects" component={Products} />
              <PrivateRoute exact path="/projects/new" component={Product} />
              <PrivateRoute exact path="/projects/view/:id" component={ViewProject} />
              <PrivateRoute exact path="/projects/:id" component={Product} />
              <PrivateRoute exact path="/projects/allocation/:id" component={ViewAllocation} />

              <PrivateRoute exact path="/activity" component={Activities} />
              <PrivateRoute exact path="/activity/new" component={Activity} />
              <PrivateRoute exact path="/activity/:id" component={Activity} />

              <PrivateRoute exact path="/events" component={Events} />
              <PrivateRoute exact path="/events/new" component={Event} />
              <PrivateRoute exact path="/events/:id" component={Event} />

              <PrivateRoute exact path="/votes" component={Voting} />
              <PrivateRoute exact path="/votes/new" component={Vote} />
              <PrivateRoute exact path="/votes/:id" component={Vote} />

              <PrivateRoute exact path="/cohort_staking" component={CohortStaking} />
              <PrivateRoute exact path="/cohort_staking/new" component={CohortStake} />
              <PrivateRoute exact path="/cohort_staking/:id" component={CohortStake} />

              <PrivateRoute exact path="/partners" component={Partners} />
              <PrivateRoute exact path="/partners/new" component={Partner} />

              <PrivateRoute exact path="/moxie_blog" component={Blogs} />
              <PrivateRoute exact path="/moxie_blog/new" component={Blog} />
              <PrivateRoute exact path="/moxie_blog/:id" component={Blog} />

              <PrivateRoute exact path="/moxie_user" component={User} />
              
              <PrivateRoute exact path="/gs_user" component={GSUser} />

              <PublicRoute exact path="/login" component={Login} />
              <Route path="*" component={() => <div className="not-found-page">Page Not Found.</div>} />

            </Switch>
          </Router>
        </Suspense>
      </ThemeProvider>
    // </AppContext.Provider>
  )
}

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => getCookie(APPLICATION_TOKEN) ? <Redirect to="/" /> : <Component {...props} />}
    />
  )

}

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return <Route
    {...rest}
    render={(props) => {
      return !getCookie(APPLICATION_TOKEN) ? (
        <Redirect to="/login" />
      ) : (
        <AdminHof {...props} >
          <Component {...props} {...rest} />
        </AdminHof>
      );
    }}
  />
};