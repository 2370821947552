import { useState, useCallback } from "react";
import axios from "axios";
import { getCookie } from "src/utils";
import { APPLICATION_TOKEN } from "src/constants";

export default function useFetch(endPoint: string) {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const getAuthToken = () => {
    let authorization = getCookie(APPLICATION_TOKEN) || "";
    authorization = "Bearer " + authorization;
    return authorization;
  };

  const getRequest = async (payload = {}): Promise<any> => {
    setLoading(true);
    try {
      const data = await axios.get(endPoint, { params: payload, headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return { data: data.data };
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data);
      return { error: err?.response?.data };
    }
  };

  const getRequestById = async (id: string, payload = {}): Promise<any> => {
    setLoading(true);
    try {
      const data = await axios.get(`${endPoint}/${id}`, { params: payload, headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return { data: data.data };
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data);
      return { error: err?.response?.data };
    }
  };

  const postRequest = async (payload = {}): Promise<any> => {
    setLoading(true);
    try {
      const response = await axios.post(endPoint, payload, { headers: { authorization: getAuthToken() } });
      setData(response?.data);
      setLoading(false);
      return { data: response?.data };
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data);
      return { error: err?.response?.data || err.message || { message: "Please check internet connection" } };
    }
  };

  const updateRequest = async (id: string, payload = {}): Promise<any> => {
    setLoading(true);
    try {
      const data = await axios.put(`${endPoint}/${id}`, payload, { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return { data: data.data };
    } catch (err: any) {
      setLoading(false);
      setError(err?.response?.data);
      return { error: err?.response?.data || err.message };
    }
  };

  const deleteRequest = async (id: string): Promise<any> => {
    setLoading(true);
    setDeleteId(id);
    try {
      const data = await axios.delete(`${endPoint}/${id}`, { headers: { authorization: getAuthToken() } });
      setDeleteId("");
      setData(data.data);
      setLoading(false);
      return { data: data.data };
    } catch (err: any) {
      setDeleteId("");
      setLoading(false);
      setError(err?.response?.data);
      return { error: err?.response?.data };
    }
  };

  const uploadImageRequest = async (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/api/v1/admin/upload", formData, { headers: { authorization: getAuthToken() } })
        .then(({ data = {} }: any) => {
          const { data: innerData = {} } = data;
          const { Location = "" } = innerData;
          resolve({ data: { link: Location } });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return { data, error, loading, getRequest, postRequest, getRequestById, updateRequest, deleteRequest, uploadImageRequest, deleteId };
}
