export const APPLICATION_TOKEN = "application-token"
export const APPLICATION_DATE_FORMAT = "DD/MM/yyyy HH:mm"
export const APPLICATION_BASE_URL = "https://api.genshards.com"
// export const APPLICATION_BASE_URL = "https://genesis-shards-platform.vercel.app"
// export const APPLICATION_BASE_URL = "https://genesis-shards-platform.herokuapp.com"
// export const APPLICATION_BASE_URL = "http://localhost:3002"

export const cohortContractAddress = {
    5: "0xe288E63316F02EBd997D0843296CA9cbC8a1aCF0", // "0x032c59183F84772C767F4E22549cE11308aE43b5"
    97: "0x3ff5cD5d742Da7933b6Fa9d50845AA3eE015C077"
}
export const factoryContractAddress = "0xAeED7Db5A6A9e67037641eaCE9660bb0F2eA43A1" // "0x2781129a431413CBd5c909E3AE63bA8D1f6D08Ee"

export const zeroAddress = "0x0000000000000000000000000000000000000000"