import ReactDOM from "react-dom";
import Axios from "axios";
import AdminRoutes from "src/adminRoutes";
import PartnerRoutes from "src/partnerRoutes";
import "src/styles/index.scss";
import { Toaster } from "react-hot-toast";
import { APPLICATION_BASE_URL } from "./constants";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import "es6-shim";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { createContext, useContext } from 'react'

Axios.defaults.baseURL = APPLICATION_BASE_URL;

function getLibrary(provider: any) {
  return new Web3(provider);
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    {process.env.REACT_APP_ENV_STATE === "admin" ? <AdminRoutes /> : <PartnerRoutes />}
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          background: "#ffffff",
          color: "#363636",
        },
        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
  </Web3ReactProvider>,
  document.getElementById("root")
);
