import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useHistory } from 'react-router-dom';

export const adminRouteList = [
  {
    name: 'Dashboard',
    url: '/',
    Icon: <DashboardIcon />,
    activeRoutes: ['/'],
  },
  {
    name: 'Projects',
    url: '/projects',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/projects', '/projects/new', '/projects/:id']
  },
  {
    name: 'Activity',
    url: '/activity',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/activity', '/activity/new', '/activity/:id']
  },
  {
    name: 'Events',
    url: '/events',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/events', '/events/new', '/events/:id']
  },
  {
    name: 'Academy',
    url: '/academy',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/academy', '/academy/new', '/academy/:id']
  },
  {
    name: 'Voting',
    url: '/votes',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/votes', '/votes/new', '/votes/:id']
  },
  {
    name: 'Cohort Staking',
    url: '/cohort_staking',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/cohort_staking', '/cohort_staking/new', '/cohort_staking/:id']
  },
  {
    name: 'Partners',
    url: '/partners',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/partners', '/partners/new']
  },
  {
    name: 'Moxie Blog',
    url: '/moxie_blog',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/moxie_blog', '/moxie_blog/new', '/moxie_blog/:id']
  },
  {
    name: 'Moxie User',
    url: '/moxie_user',
    Icon: <PlaylistAddIcon />,  
    activeRoutes: ['/moxie_user']
  },
  {
    name: 'GS User',
    url: '/gs_user',
    Icon: <PlaylistAddIcon />,  
    activeRoutes: ['/gs_user']
  },
]

export const partnerRouteList = [
  {
    name: 'Dashboard',
    url: '/',
    Icon: <DashboardIcon />,
    activeRoutes: ['/'],
  },
  {
    name: 'Staking',
    url: '/staking',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/staking'],
  },
  {
    name: 'Diamond Hands',
    url: '/diamond_hands',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/diamond_hands'],
  },
  {
    name: 'Cohort Staking',
    url: '/cohort_staking',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/cohort_staking'],
  },
]

export default function MainListItems(props: { selectedRoute: any }) {
  const routeList = process.env.REACT_APP_ENV_STATE === "admin" ? adminRouteList : partnerRouteList
  const { selectedRoute = {} } = props
  const history = useHistory()
  return <React.Fragment>
    {
      routeList.map((route, index) => {
        const isActive = route.url === selectedRoute.url
        return <ListItemButton
          key={index}
          selected={isActive}
          onClick={() => history.push(route.url)}
          sx={[
            {
              '&.Mui-selected': {
                background: (t) => t.palette.mode === "dark" ? t.palette.primary.dark : t.palette.primary.light
              },
              '&.Mui-selected:hover': {
                background: (t) => t.palette.mode === "dark" ? t.palette.primary.dark : t.palette.primary.light
              },
            }
          ]}
        >
          <ListItemIcon>
            {route.Icon}
          </ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItemButton>
      }
      )}
  </React.Fragment>
}