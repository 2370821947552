import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber, Contract, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils'
import cookie from 'js-cookie'
// import { toast, Slide } from "react-toastify"
import { APPLICATION_TOKEN } from 'src/constants'
import Web3 from "web3"
import { AddressZero } from '@ethersproject/constants'

export function setCookie(name: string, value: string): void {
    if (value) cookie.set(name, value)
}

export function getCookie(name: string): string | null | undefined {
    if (cookie.get(name)) return cookie.get(name)
    return null
}

export function deleteCookie(name: string): void {
    if (cookie.get(name)) cookie.remove(name)
}

export function setLocalData(name: string, value: string): void {
    if (value) localStorage.setItem(name, value)
}

export function getLocalData(name: string): string | null | undefined {
    // if (cookie.get(name)) return cookie.get(name)
    if (name) JSON.parse(localStorage.getItem(name) || '')
    return null
}

export function setTimer(callback: any, timer: number) {
    setTimeout(() => { callback() }, timer)
}

export function removeAuthObjects() {
    deleteCookie(APPLICATION_TOKEN)
}


// export function toastSuccess(message: string, time = 3000) {
//     toast.success(message, {
//         autoClose: time,
//         hideProgressBar: true,
//         position: toast.POSITION.TOP_RIGHT,
//         transition: Slide
//     })
//     clearWaitingQueue()
// }

// export function toastError(message: string, time = 3000) {
//     toast.error(message || 'Something went wrong.', {
//         autoClose: time,
//         hideProgressBar: true,
//         position: toast.POSITION.TOP_RIGHT,
//         transition: Slide
//     })
//     clearWaitingQueue()
// }

// export function clearWaitingQueue() {
//     toast.clearWaitingQueue();
// }

export const getTwoDecimalValue = (value: any) => {
    const split = String(value).split(".")
    let decimal = ""
    if (split.length > 1) {
        if (split[1].length > 2) {
            for (let i = 0; i < 2; i++) {
                decimal = decimal + String(split[1]).charAt(i)
            }
        } else {
            decimal = split[1]
        }
        decimal = split[0] + "." + decimal
    } else {
        decimal = String(value)
    }
    return Number(decimal)
}

export function convertJSONtoCSV(items = []) {
    let csv = [] as any
    if (items.length) {
        var keys = Object.keys(items[0])
        csv.push(keys.join(','))
        items.forEach(item => {
            let vals = keys.map(key => item[key] || '')
            csv.push(vals.join(','))
        })
    }
    csv = csv.join('\n')
    return csv
}

export function removeKeysFromObject(originalObj = {}, toBeRemoved = []) {
    const omit = (keys: any, obj: any) =>
        Object.fromEntries(
            Object.entries(obj)
                .filter(([k]) => !keys.includes(k))
        )
    return omit(toBeRemoved, originalObj)
}

export function createContractEthers(contractAddress: string, abi: any) {
    const { ethereum } = window as any
    const provider = new ethers.providers.Web3Provider(ethereum) as any
    const signer = provider.getSigner()
    const contract = new ethers.Contract(contractAddress, abi, signer)
    return contract
}

export function createContract(contractAddress: string, abi: any) {
    const { ethereum } = window as any
    const web3 = new Web3(ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress)
    return contract
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
    try {
        return getAddress(value)
    } catch {
        return false
    }
}

// // account is optional
// export async function getProviderOrSigner(library: Web3Provider, account?: string) {
//     const { ethereum } = window as any

//     // Web3 browser user detected. You can now use the provider.
//     const accounts = await ethereum.enable();
//     // const curProvider = window['ethereum'] || window.web3.currentProvider

//     const provider = new ethers.providers.Web3Provider(ethereum);

//     console.log('accounts: ', accounts);
//     console.log('provider: ', provider);

//     return provider.getSigner();
//     // return account ? getSigner(library, account) : library
// }

// account is optional
export async function getContract(
    address: string,
    ABI: any,
    library: Web3Provider,
    account?: string
) {
    // throw Error(`Invalid 'address' parameter '${address}'.`)
    library = await library
    return new Contract(
        address,
        ABI,
        library
    )
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
    return value
        .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
        .div(BigNumber.from(10000))
}